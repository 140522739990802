import React from 'react'
import tailwindConfig from 'tailwind.config'

interface IProps {
  direction?: 'UP' | 'DOWN' | 'LEFT' | 'RIGHT'
  color?: string
}

const Arrow = ({
  direction = 'UP',
  color = tailwindConfig.theme.extend.colors.neutralsAdditional800
}: IProps) => {
  if (direction === 'DOWN')
    return (
      <>
        <svg
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.9091 6.0134L12.0782 0.327334C12.4498 -0.0813234 13.0822 -0.11144 13.4909 0.260067C13.8995 0.631574 13.9296 1.26402 13.5581 1.67268L7.64904 8.17268C7.45952 8.38115 7.19084 8.50001 6.9091 8.50001C6.62735 8.50001 6.35868 8.38115 6.16916 8.17268L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"
            fill={color}
          />
        </svg>
      </>
    )
  else if (direction === 'LEFT')
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.40887 10.9685L18.0002 10.9685C18.2807 10.9685 18.5175 11.0679 18.7105 11.2667C18.9035 11.4655 19 11.7093 19 11.9983C19 12.2873 18.9035 12.5311 18.7105 12.7299C18.5175 12.9287 18.2807 13.0281 18.0001 13.0281L8.40887 13.0281L12.5109 17.2529C12.7112 17.4592 12.8086 17.7011 12.803 17.9786C12.7974 18.2561 12.6945 18.498 12.4942 18.7043C12.2939 18.8955 12.059 18.994 11.7896 18.9998C11.5201 19.0055 11.2853 18.9052 11.085 18.6989L5.28375 12.724C5.18534 12.6226 5.11344 12.5101 5.06806 12.3865C5.02269 12.2628 5 12.1334 5 11.9983C5 11.8631 5.02269 11.7337 5.06806 11.6101C5.11344 11.4864 5.18534 11.3739 5.28376 11.2726L11.0902 5.29227C11.2794 5.09742 11.5106 5 11.7838 5C12.0571 5 12.2939 5.09742 12.4942 5.29227C12.6945 5.49859 12.7946 5.74336 12.7946 6.02658C12.7946 6.30981 12.6945 6.55458 12.4942 6.7609L8.40887 10.9685Z"
            fill={color}
          />
        </svg>
      </>
    )
  else if (direction === 'RIGHT')
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5911 13.0315L6.00002 13.0315C5.7195 13.0315 5.48269 12.9321 5.28972 12.7333C5.09676 12.5345 5.00002 12.2907 5.00002 12.0017C5.00002 11.7127 5.09676 11.4689 5.28972 11.2701C5.48269 11.0713 5.7195 10.9719 6.00004 10.9719L15.5912 10.9719L11.4891 6.74709C11.2888 6.54081 11.1914 6.29889 11.197 6.02142C11.2026 5.74395 11.3055 5.50206 11.5058 5.29578C11.7061 5.10452 11.941 5.00605 12.2104 5.00028C12.4799 4.99451 12.7147 5.09484 12.915 5.30116L18.7162 11.2761C18.8147 11.3775 18.8866 11.4901 18.932 11.6137C18.9774 11.7372 19.0001 11.8666 19.0001 12.0017C19.0001 12.1369 18.9774 12.2663 18.932 12.3899C18.8866 12.5136 18.8147 12.6261 18.7162 12.7274L12.9098 18.7077C12.7206 18.9026 12.4894 19.0001 12.2162 19.0001C11.9429 19.0001 11.7061 18.9026 11.5058 18.7077C11.3055 18.5014 11.2054 18.2566 11.2054 17.9734C11.2054 17.6902 11.3055 17.4455 11.5058 17.2392L15.5911 13.0315Z"
            fill={color}
          />
        </svg>
      </>
    )
  else
    return (
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.491 8.23993C13.0824 8.61144 12.4499 8.58132 12.0784 8.17267L6.90926 2.4866L1.74011 8.17266C1.3686 8.58132 0.736156 8.61144 0.327498 8.23993C-0.0811595 7.86842 -0.111277 7.23598 0.260231 6.82732L6.16932 0.32732C6.35884 0.118846 6.62752 -7.30436e-06 6.90926 -7.27973e-06C7.19101 -7.2551e-06 7.45968 0.118846 7.6492 0.327321L13.5583 6.82732C13.9298 7.23598 13.8997 7.86843 13.491 8.23993Z"
          fill={color}
        />
      </svg>
    )
}

export default Arrow
