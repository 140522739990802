import React from 'react'
import Heading from '@/components/typography/heading'
import { FormattedMessage } from 'react-intl'
import Alert, { MODE } from '@/components/base/Alert'

export const Introduction = () => {
  return (
    <>
      <div className="pt-4 pb-6 pl-8 pr-4 border-b border-b-grey200">
        <div className="max-w-[656px] ">
          <Heading className="mb-2">
            <FormattedMessage id="ecr.task.intro.title" />
          </Heading>
          <p className="mb-5">
            <FormattedMessage id="page.generic.ecrGuidance1" />
          </p>
          <p>
            <FormattedMessage id="page.generic.ecrGuidance2" />{' '}
          </p>
        </div>
      </div>
      <div className="px-8 pt-6 pb-8">
        <Alert
          className="max-w-[792px] mb-4 mt-0 border-SecondaryBlue50 bg-pressedMenuItem/25"
          mode={MODE.INFO}
        >
          <p className="mb-5 text-base text-hoverBlue">
            <FormattedMessage id="page.generic.ecrVleiGuidance1" />
          </p>
          <p className="text-base text-hoverBlue">
            <FormattedMessage id="page.generic.ecrVleiGuidance2" />
          </p>
        </Alert>

        <Alert className="max-w-[792px] mb-0" mode={MODE.WARNING}>
          <p>
            <FormattedMessage id="page.generic.ecrRecommendation" />
          </p>
        </Alert>
        {/* <div className="max-w-[792px] p-3 border-solid rounded-md  flex gap-2 justify-start items-start bg-[#FFF4E8] border-[1px] border-[#FFE9C0] text-[#D96800] shadow-[0_1px_2px_0px_rgba(16,24,40,0.05)]">
            <ErrorSquare className="mr-1.5" />
            
          </div> */}
      </div>
    </>
  )
}
