import { Recipient } from './types'
import type { PossibleOorRecipientsResponseDto } from '@/api/origin-workflow-svc'

/**
 * Converts an array of ProposedRecipientDto objects to an array of Recipient objects.
 *
 * @param {ProposedRecipientDto | null | undefined} proposedRecipientDto - The DTO to convert.
 * @returns {Recipient[]} - The array of converted Recipient objects.
 */
export const convertProposedRecipientDto = (
  proposedRecipientDto: PossibleOorRecipientsResponseDto | null | undefined
): Recipient[] => {
  if (
    !proposedRecipientDto ||
    !proposedRecipientDto.recipients ||
    proposedRecipientDto.recipients.length === 0
  ) {
    return []
  }

  let AllUsers = []

  // todo speak with BE team to update swagger docs
  // @ts-ignore
  proposedRecipientDto.recipients.map((user) =>
    user.titleAndGrantStatusList.map((titleAndGrantStatus) => {
      if (titleAndGrantStatus.title.toLowerCase() !== 'employee')
        AllUsers.push({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.emails?.[0] || '',
          orgName: user.orgName || '',
          oorTitle: titleAndGrantStatus.title || '',
          shortTitle: titleAndGrantStatus.shortTitle,
          status: titleAndGrantStatus.grantStatus || '',
          error: null,
          userId: user.userId
        })
    })
  )
  console.log('🚀 ~ file: util.ts:38 ~ AllUsers:', AllUsers)
  return AllUsers
}
